import gsap from "gsap";

// import Flip from "gsap/Flip";
// import ScrollTrigger from "gsap/ScrollTrigger";
// import ScrollToPlugin from "gsap/ScrollToPlugin";

// gsap.registerPlugin(Flip, ScrollTrigger, ScrollToPlugin);

gsap.to('h1', {
  autoAlpha: 0,
  repeat: -1,
  yoyo: true
})